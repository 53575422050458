body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e6e6e6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

/* property css */

label {
  display: flex;
  align-items: center;
  gap: 10px;
}

input {
  border: none;
  background: #ecebeb;
  font-size: 14px;
  padding: 5px;
  border-radius: 8px;
}

button {
  padding: 5px 10px;
  background-color: #fff;
  border-radius: 8px;
  text-decoration: none;
  /* color: #333; */
  border: none;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 1px 0px 10px #b6b6b6;
}

button:hover {
  transform: scale(1.1);
  transition: ease-in-out 330ms;
  box-shadow: 1px 0px 10px #838383;
}

select {
  padding: 5px 15px;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  background: #ecebeb;

}

textarea {
  border: none;
  border-radius: 8px;
  background: #ecebeb;
  padding: 10px;
}

/* Main css */

.container {
  padding-top: 3vw;
  padding-left: 18vw;
  padding-bottom: 3vw;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container-row {
  padding-top: 3vw;
  padding-left: 18vw;
  padding-bottom: 3vw;
  display: flex;
  /* flex-direction: column; */
  gap: 20px;
}

.row {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  /* flex-direction: column; */
  gap: 20px;
}

.divider {
  width: max-content;
  border-bottom: 1px solid #d3d3d3;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 20px;
}

.button {
  padding: 5px 10px;
  background-color: #FBD7D7;
  border-radius: 8px;
  text-decoration: none;
  color: #333;
  border: none;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 1px 0px 10px #b6b6b6;
}

.button:hover {
  transform: scale(1.1);
  transition: ease-in-out 330ms;
}

.hover:hover {
  background-color: #d3d3d3;
}

.hover-icon:hover {
  transform: scale(1.4);
  transition: ease-in-out 330ms;
  cursor: pointer;
}

.grid-3 {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.grid-5 {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.gap-15 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 150px;
}

/* ----- Flex ----- */

.space-between-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ------ Modal ------ */

.blur_container {
  top: 0;
  left: 0;
  width: 99.2vw;
  min-height: 100vh;
  height: 150vh;
  max-height: max-content;
  backdrop-filter: blur(10px);
  position: absolute;
  background-color: #ffffff21;
  display: flex;
  justify-content: center;
  align-items: start;
  z-index: 2000;
  /* padding: 50px; */
}

.modal {
  z-index: 2000;
  margin: 100px;
  padding: 50px;
  width: 40vw;
  height: max-content;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 1px 10px #939393;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: start;
  gap: 30px;
}

.two-line {
  width: 40vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* ------ Text ------ */

.mini-bold-text {
  font-weight: 600;
  font-size: 14px;
}

.mini-grey-text {
  color: #838383;
  font-size: 12px;
}

/* ---- box text ---- */

.grey-bg-text {
  background-color: #e6e6e6;
  padding: 10px 20px;
  border-radius: 12px;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.grey-bg-text:hover {
  transform: scale(1.1);
  transition: 330ms ease-in-out;
}

/* slider */

.slider {
  width: 80vw;
}

.slider img {
  width: 75vw;
  height: 50vh;
}