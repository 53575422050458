.d_card {
    padding: 10px 0;
    border-radius: 8px;
    background: #fff;
    display: flex;
    flex-direction: column;
    width: max-content;
    gap: 10px;
    align-items: start;
    justify-content: center;
}

.d_card p {
    padding: 0 15px;
    font-size: 16px;
    color: #838383;
}

.d_card h3 {
    padding: 0 15px;
}