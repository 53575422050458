.container {
    width: 16vw;
    background-color: #FBD7D7;
    height: 98vh;
    max-height: 100vh;
    position: fixed;
    padding: 10px 10px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.user_card {
    cursor: pointer;
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    background-color: #fff;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
}

.user_card:hover {
    background-color: #c3c2c2;
    transition: 330ms ease-in-out;
}

.profile {
    width: 40px;
    height: 40px;
    border-radius: 15px;
}

.items {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    cursor: pointer;
    gap: 15px;
}

.unSelectItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 10px;
    background-color: #f5f4f45a;
    padding: 10px;
    border-radius: 8px;
    width: 15vw;
    text-decoration: none;
    color: #939393;
}

.item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 10px;
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    width: 15vw;
    text-decoration: none;
    color: #000;
}

.unSelectItem:hover {
    box-shadow: 1px 0px 10px #888888;
    background-color: #fff;
}